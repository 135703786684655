import { detectAnyAdblocker } from 'just-detect-adblock';
import { MeterManager } from './meter/manager.js';
import { detectIncognito } from "detectincognitojs";

window.TNCMS = window.TNCMS || {};
var aCallbacks = window.TNCMS.Access || [];

/* {{{ Initializer: TNCMS.Access */

/**
 * Client side access checks
 *  
 */
window.TNCMS.Access = (function() {
	
	/* {{{ Properties */

	/*
	* If ad blocking detection is needed by a rule - this will be the cached
	* detected value for any further rule evaluation
	* @type {boolean}
	*/	
	let bAdBlock = null;

	/**
	 * If incognito detection is needed by a rule - then this will be the cached
	 * detected value for any further rule evaluation
	 */
	let bIncognito = null;

	/**
	 * Meter manager if being used for handling the meter
	 * @type {MeterManager}
	 */	
	let oMeterMgr;
	
	/**
	 * The last access response state that was determined for the current user
     * of the site
     * @type {Object}
	 */
	let oAccessState = null;
	
	var aRules = null,
		oOffers = null,
		aMeters = null,
		bLoaded = false,
		aAudiences = null,
		aRequiresClient = null,
		// Criteria that does not change coming from the page meta tags
		// includes things such as asset type
		kPageCriteriaIDs = {},
		// Criteria based on the current user that may change if an action
		// such as login occurs in an XHR request
		kCriteriaIDs = {},
		aAccessIDs = [],
		sAssetID = '',
		sAssetApp = '',
		onSuccess = null,
		onFailure = null,
		sRuleVersion = '',
		// Properties used in external mode
		bIsExternal = false,
		sExternalURL = '',
		sExternalReferrer = null,
		aMetaTags = [],
		// Admin preview test profile
		oProfile = {};
	
	/* }}} */
	/* {{{ handleError( oData ) */
	
	function handleError( oData ) {
		var oErr = {
			success: false,
			msg: oData.msg || 'Unknown error occurred',
			xhrStatus: oData.status || '',
			xhrStatusText: oData.statusText || ''				
		};
		
		verbose('Error: ', oErr);
		
		if ( typeof onFailure == 'function' ) {
			onFailure.call(this, oErr);
		}
	};
	
	/* }}} */
	/* {{{ verbose( sMsg ) */
	
	function verbose( sMsg, oData ) {
		if ( ! console ) return;
		
		if ( typeof oData != 'undefined' ) {
			console.debug('TNCMS.Access: ' + sMsg, oData);
		} else {
			console.debug('TNCMS.Access: ' + sMsg);
		}		
	};
	
	/* }}} */
	/* {{{ getMetaValue( sField ) */
	
	function getMetaValue( sField ) {
		let oNodes = document.getElementsByName(sField);
		return oNodes.item(0)?.content ?? '';
	};
	
	/* }}} */
	
	
	// This is loaded immediately so other functions using it have access
	// with out the need to call init()
	
	sRuleVersion = getMetaValue('tncms-access-version');
	
	/* {{{ init() */
	
	/**
	 * Initialize the subsystem and begin the chain of 
	 * requests to check access on a page starting with 
	 * the load of rules, user data and audiences if needed.
	 * 
	 */
	async function init() {
		
		// Extract asset ID and app if in preview
		// Asset ID may have been initialized via external meta tags
		if ( ! sAssetID ) {
			sAssetID = getMetaValue('tncms-access-asset-id');
			verbose('Asset ID', sAssetID);
		}
		
		sAssetApp = getMetaValue('tncms-access-asset-app');
		verbose('Asset app', sAssetApp);			
	
		// Extract criteria IDs
		
		let sCriteria = getMetaValue('tncms-access-criteria');
		if ( sCriteria ) {
			kPageCriteriaIDs = JSON.parse(sCriteria);
		}
		
		verbose('Page criteria IDs', kPageCriteriaIDs);

		// Extact rule version
				
		sRuleVersion = getMetaValue('tncms-access-version');		
		verbose('Rule version', sRuleVersion);		
		
		if ( aRules !== null ) {
			processRules.call(this);
			return;
		}		
		
		loadRules.call(this);
	};

	/* }}} */
	/* {{{ loadRules() */
	
	/**
	 * Call out to the system to gather all enabled
	 * rules and other metadata to fulfill the access check.
	 * 
	 */
	function loadRules() {			
		var oRequest = new Request({
			url: 'rules/',
			headers: [{ name: 'X-TNCMS-Access-Version', value: sRuleVersion}],
			success: function ( oResp ) {
				var oData = JSON.parse(oResp.responseText);
				if ( oData.success == false ) {
					handleError(oData);
					return;
				}
				
				aRules = oData.rules;
				aMeters = oData.meters || [];
				aRequiresClient = oData.requires_client || [];
				oOffers = oData.offers || {};
				
				oMeterMgr = new MeterManager({
					host: document.location.hostname,
					meters: aMeters,
					sync: window.tncms_access_control_sync ?? false,
					debug: true
				});
				
				verbose('Rules loaded', aRules);
				verbose('Offers loaded', oOffers);
				
				loadUser.call(this);
			},
			failure: handleError,
			scope: this
		});
		
		oRequest.send();
	};
	
	/* }}} */	
	/* {{{ loadUser( oParams ) */
	
	function loadUser( oParams ) {
		var aCookies = decodeURIComponent(document.cookie).split(';'),
			oMatch = null,
			n = 0,
			aHeaders = [],
			sUser = 'anonymous';
		
		for ( n; n < aCookies.length; n++ ) {
			oMatch = aCookies[n].match(/tncms-screenname=(.+)$/i);
			if ( oMatch
				// Make sure they're actually logged in
				&& document.cookie.indexOf('tncms-authtoken') > -1
			) {
				sUser = 'loggedin-' + oMatch[1];
			}
			oMatch = aCookies[n].match(/tncms-access-user-version=(.+)/i);
			if ( oMatch ) {
				aHeaders.push({ name: 'X-TNCMS-Access-User-Version', value: oMatch[1] });
			}
		}
				
		aHeaders.push({ name: 'X-TNCMS-Access-User', value: sUser});
		
		var oParams = false;
		if ( sAssetID && sAssetApp 
			&& document.location.pathname.match(
				/\/(.+?)\/tncms\/admin\/action\/main\/preview\/site\//
			)
		) {
			oParams = {
				asset_id: sAssetID,
				asset_app: sAssetApp
			};
			
			// Look for sessionStorage data only in preview mode
			
			if ( sessionStorage.getItem('accessProfile') ) {
				try {
					
					oProfile = JSON.parse(sessionStorage.getItem('accessProfile'));
					verbose('Using test profile', oProfile);
					
					// Profile ID is included for XHR request to /user 
					// so it can test against profile data associated to ID.
					oParams.profile = oProfile.id;
					
					// Set default ad blocking mode if configured or force to
					// undefined so it will auto-detect
					bAdBlock = oProfile?.adblock ?? undefined;
					
					let sKey = '',
						nCount = 0;
					
					for ( sKey in oProfile ) {
						if ( ! oProfile.hasOwnProperty(sKey) ) continue;
						
						/* TODO: Use server-side to control this */
						/*
						oMatch = sKey.match(/meter_(.+)$/i);
						if ( oMatch && ! oProfile.initialized ) {
							// set meter to defined number or 0
							nCount = parseInt(oProfile[sKey], 10) || 0;
							setMeterCount('tncms:meter:assets' + oMatch[1], nCount);
						}
						*/
						
						if ( sKey == 'dmp' ) {
							try {
								let aProfileAud = JSON.parse(oProfile[sKey]);
								aAudiences = [];
								for ( let n = 0; n < aProfileAud.length; n++ ) {
									aAudiences.push(aProfileAud[n].abbr);
								}
								verbose('Using profile audiences', aAudiences);
							} catch ( oExc ) {
								verbose('Failed to parse profile audiences', oExc);
							}
						}
					}
					
					verbose('Meters set to profile configuration');
					oProfile.initialized = true;
					sessionStorage.setItem('accessProfile', JSON.stringify(oProfile));
					
				} catch ( oExc ) {
					verbose('Failed to parse test profile', oExt);
				}
			}
		}
		
		var oRequest = new Request({
			url: 'user/',
			headers: aHeaders,
			params: oParams,
			success: function ( oResp ) {					
				var oData = JSON.parse(oResp.responseText);				
				if ( oData.success == false ) {
					handleError(oData);
					return;
				}	
				
				if ( oData.access_ids && oData.access_ids.length ) {
					aAccessIDs = aAccessIDs.concat(oData.access_ids);
				}
				
				kCriteriaIDs = kPageCriteriaIDs;
				if ( oData.criteria_ids ) {					
					for ( let sRuleID in oData.criteria_ids ) {
						if ( ! kCriteriaIDs[sRuleID] ) {
							kCriteriaIDs[sRuleID] = [];
						}
						kCriteriaIDs[sRuleID] = kCriteriaIDs[sRuleID].concat(oData.criteria_ids[sRuleID]); 
					}
				}
				
				verbose('User access IDs', aAccessIDs);
				verbose('User criteria IDs', kCriteriaIDs);
				
				bLoaded = true;
				processRules.call(this);
			},
			failure: handleError,
			scope: this
		});
		
		oRequest.send();
	};
	
	/* }}} */
	/* {{{ processRules() */
	
	/**
	 * Process all gathered rules and return an 
	 * object containing the response data.
	 * 
	 */
	async function processRules() {
		if ( ! bLoaded ) {
			return;
		}		
		// if audiences are required but have not yet been loaded
		if ( aRequiresClient.indexOf('dmp') !== -1 && aAudiences == null ) {
			return;
		}
		
		// Make sure the meter manager is ready to process rules
		
		if (await oMeterMgr.ready()) {
			verbose('Meter manager is loaded and ready to be used');
		}
		
		verbose('Processing rules');
		
		let nRequired = 0,
			oURL = null,
			nProcessed = 0;
		
		oAccessState = {
			required: false,
			access_methods: [],
			granting_method: null,
			access_rule: {},
			offer_config: {}
		};
		
		/**
		 * Match a query string parameter in the URL, falling
		 * back to sessionStorage.
		 * 
		 * @param object oParam
		 *   Anonymous object containing name, value, match and preserve keys.
		 *   The match key will be either exact or partial.
		 * @retrurn bool
		 */
		function matchParam(oParam) {
			const oParams = new URLSearchParams(document.location.search);
			
			let xValue = oParams.get(oParam.name);
			if ( ! xValue && oParam.preserve ) {
				xValue = sessionStorage.getItem('access-qp-' + oParam.name);
			}
			
			if ( ! xValue ) {
				return false;
			}
			
			let bHasMatch = false;			
			if ( oParam.match == 'exact' && oParam.value == xValue ) { 
				bHasMatch = true;				
			} else if ( xValue.indexOf(oParam.value) != -1 ) {
				bHasMatch = true;
			}
			
			if ( bHasMatch && oParam.preserve ) {
				sessionStorage.setItem('access-qp-' + oParam.name, xValue);				
			}

			return bHasMatch;
		};
		
		// Allow any previously viewed asset to have access
		
		if (sAssetID && oMeterMgr.inAssetList(sAssetID)) {
			verbose('Asset already viewed');
			onSuccess.call(this, oAccessState);
			return;
		}		
		
		// Fall back to `this` pages meta tags if none have been set
		
		if ( aMetaTags.length == 0 ) {
			aMetaTags = document.getElementsByTagName('meta');
		}

		// Examine all rules
		
		for (const kRule of aRules) {
			
			let aRuleMeters = [];
			
			nRequired = 0;
			nProcessed = 0;
			
			// List all possible meters for this rule 
			
			for (const oMethod of kRule.methods) {
				
				if ( oMethod.type == 'meter' ) {
					aRuleMeters.push(oMethod.id);
				}

			}
			
			verbose('Processing rule ' + kRule.name);
			
			// All criteria for a rule must match 
			
			for (const kCriteria of kRule.criteria) {

				nProcessed++;
				
				verbose('Processing criteria', kCriteria.name);				
				
				switch ( kCriteria.driver ) {
				
				case 'queryparams':
					let aParams = kCriteria.data.queryparams || [],
						n = 0,
						bMatch = false;
					
					for ( const oParam of aParams ) {
						if ( matchParam(oParam)) {
							bMatch = true; 
							break;
						}
					}
					
					if ( bMatch && kCriteria.negate != 1 ) {
						verbose('Matched query parameter', aParams);
						nRequired++;						
					}
					
					break;
					
				case 'metercheck':
					let sMeterID = kCriteria.data.meter,
						nViews = kCriteria.data.views,
						sMeterOp = kCriteria.data.op;
					
					if ( kCriteria.negate == 1 ) {
						verbose('Negate is not supported by meter check');
					}
					
					for ( const kMeter of aMeters ) {
					
						// This meter matches the meter check criteria
						// And is assigned as an access method for this rule
						if ( kMeter.id == sMeterID
							&& aRuleMeters.indexOf(kMeter.id) !== -1
						) {
							verbose('Checking meter ' + kMeter.name);
							// Add one to the meter to emulate what the view count would be
							// if provided access by this meter
							let nMeterCount = oMeterMgr.getViews(kMeter.id) + 1;

							switch ( sMeterOp ) {
							case '=':
								if ( nMeterCount == nViews ) {
									verbose('Meter count equals views', nMeterCount);
									nRequired++;
								}
								break;
							case '<':
								if ( nMeterCount < nViews ) {
									verbose('Meter count is less than views', nMeterCount);
									nRequired++;
								}
								break;
							case '<=':
								if ( nMeterCount <= nViews ) {
									verbose('Meter count is less than or equal to views', nMeterCount);
									nRequired++;
								}
								break;
							case '>':
								if ( nMeterCount > nViews ) {
									verbose('Meter count is greater than views', nMeterCount);
									nRequired++;
								}
								break;
							case '>=':
								if ( nMeterCount >= nViews ) {
									verbose('Meter count is greater than or equal to views', nMeterCount);
									nRequired++;
								}
								break;
							}
						}
					}
					break;
					
				case 'dmp':
					verbose('Processing DMP', aAudiences);
					for ( const oDMP of kCriteria.data.dmp ) {	

						// Test profile will have defined aAudiences if DMP was used
						if (aAudiences.indexOf(oDMP.abbr) !== -1) {
							if (kCriteria.negate != 1) {
								verbose('Found audience', oDMP);
								nRequired++;
								break;
							}
						}
					}
					break;
					
				case 'urlpattern':					
					oURL = new URL(sExternalURL || window.location.href);
					verbose('Processing urlpattern against', oURL.pathname);
					
					for ( const oPattern of kCriteria.data.pattern ) {

						let oReg = new RegExp(oPattern.replace('*', '.*'), 'i'),
							aMatch = null;
						
						aMatch = oReg.exec(oURL.pathname);
						if ( aMatch && kCriteria.negate != 1 ) {
							verbose('URL pattern match', oPattern);
							nRequired++;
							break;
						}
					}
					break;
					
				case 'hostname':
					oURL = new URL(sExternalURL || window.location.href);
					verbose ('Processing host name against', oURL.hostname);
					
					for ( const oHost of kCriteria.data.host ) {

						if ( oHost.toLowerCase() 
							== oURL.hostname.toLowerCase()
							&& kCriteria.negate != 1
						) {
							verbose('Host name match', oHost);
							nRequired++;
							break;
						}
					}
					break;
					
				case 'metatag':
					verbose('Processing meta tags', aMetaTags);
					meta_loop:
					for ( const oMeta of aMetaTags ) {
						
						for ( const oMetaCrit of kCriteria.data.meta ) {
							if ( oMeta.name == oMetaCrit.name
								&& oMeta.content == oMetaCrit.value
								&& kCriteria.negate != 1
							) {
								verbose('Meta tag match', oMeta);
								nRequired++;
								break meta_loop;
							}
						}
					}
					break;
					
				case 'referrer':					
					var sReferrer = document.referrer.toLowerCase(),
						aCheckReferrer = kCriteria.data.referrer,
						sCheck = '',
						bExists = false;
					// If external mode look for external referrer
					if ( bIsExternal ) {
						sReferrer = sExternalReferrer || '';
					}
					// Test profile declared referrer
					if ( oProfile.referrer ) {
						verbose('Using profile referrer', oProfile.referrer);
						sReferrer = oProfile.referrer;
					}
					
					verbose('Processing referrer', sReferrer);
					
					if ( sReferrer && sReferrer.length > 0 ) {
						
						for ( const oReferrer of aCheckReferrer ) {

							sCheck = oReferrer.name.toLowerCase();
							bExists = sReferrer.indexOf(sCheck) !== -1;					
							if ( bExists && kCriteria.negate != 1 ) {
								verbose('Found referrer', kCriteria);
								nRequired++;
								break;
							}
						}
					}
					break;

				case 'adblock':
					// Perform ad block detection if not cached
					
					if ( typeof bAdBlock !== 'boolean' ) {
						
						if (window?.dataLayer.find(oEl => oEl?.event === 'tncms.ad.blocked')) {
							
							verbose('Ad blocking detected by BLOX core');
							bAdBlock = true;
							
						} else {
							
							try {
								
								bAdBlock = await detectAnyAdblocker();
								verbose('Ad blocker detected?', bAdBlock);
								
							} catch(oError) {
								
								verbose('Failed to detect ad blocking state', oError);
								bAdBlock = false;
								
							}
							
						}
						
					}

					if ( bAdBlock != (kCriteria.negate == 1) ) {
						nRequired++;
					}
					break;
					
				case 'incognito':
					var bNegate = (kCriteria.negate == 1); // get criteria's negation as a boolean to compare bIncognito against

					if (typeof bIncognito !== 'boolean') {
						
						try {
							
							// Test profile declared incognito mode
							if ( oProfile.incognito ) {

								verbose('Using profile incognito mode', true);
								bIncognito = true;

							} else {

								let oResult = await detectIncognito();
								bIncognito = oResult.isPrivate;
								if (bIncognito) {
									verbose('Private browsing mode detected');
								}
								
							}
						
						} catch(oError) {
						
							bIncognito = false;
							verbose('Private mode detection failed', oError);
						
						}

					}
					if (bIncognito != bNegate) {
						nRequired++;
					}
					break;
					
				case 'custom':
					verbose('Processing custom', kCriteria.name);
					
					let bRequired = null;
					
					try {
						
						let oFunc = new Function(kCriteria.data.javascript);
						
						bRequired = oFunc();
						
					} catch ( oErr ) {
						verbose('Custom function did not execute properly', oErr);
					}
					
					if ( typeof bRequired !== 'boolean' ) {
						verbose('Custom function did not return a boolean', bRequired);
					} else if ( bRequired === true && kCriteria.negate != 1 ) {
						verbose('Custom JS requires access');
						nRequired++;
					} else if ( bRequired === false && kCriteria.negate == 1 ) {
						verbose('Custom JS requires access (negated)');
						nRequired++;
					}
					
					break;
				default:		
					if ( kCriteriaIDs[kRule.id]
						&& kCriteriaIDs[kRule.id].indexOf(kCriteria.id) !== -1
					) {
						verbose('Criteria matched', kCriteria);
						nRequired++;
					}
					break;
				}
			}
			
			verbose('Total criteria processed: ', nProcessed);
			verbose('Total matching criteria: ', nRequired);
			
			// There is at least 1 criteria and all criteria matched			
			// Check if the user has any access granted for the rule
			// that has just been matched.
			
			if ( nProcessed && nProcessed == nRequired ) {	
				verbose('Access required');
				oAccessState = hasAccess.call(this, kRule);
				break;
			}
		}
		
		// If access is required, contains a redirect
		// and it's not been redirected before

		if ( oAccessState.required
			&& oAccessState.offer_config.redirect_url
			&& oAccessState.offer_config.redirect_url.length
			&& ! (history.state || (history.state && history.state.redirected))
		) {
			history.pushState({ redirected: true }, 'OfferRedirect');
			location = oAccessState.offer_config.redirect_url;	
		}
		
		onSuccess.call(this, oAccessState);
	};	
	
	/* }}} */
	/* {{{ hasAccess( kRule ) */
	
	/**
	 * Check if a user has any access methods that the rule
	 * is configured to use for access.
	 */
	function hasAccess( kRule ) {	
		var oAccessAllowed = {
			required: false,
			access_rule: {},
			access_methods: [],
			access_meter: null
		};
		
		let aReturnMethods = [];
		
		for ( const kMethod of kRule.methods ) {
			
			verbose('Examining access method', kMethod);
						
			// If they are viewing an asset page and this meter is in the list
			// of meters they have access to it will automatically be granted
			// access
			
			if ( kMethod.type == 'meter' && sAssetID ) {
				
				const oMeter = aMeters.find(oEl => oEl.id == kMethod.id);
				if ( !oMeter ) {
					continue;
				}
				
				verbose('Examining meter', oMeter);
					
				const nCount = oMeterMgr.getViews(kMethod.id);
						
 				verbose('Meter applies at limit ', oMeter.meter); 	
 				verbose('Current count', nCount);
							
 				// The asset has already been viewed via a meter
	
				if (oMeterMgr.inAssetList(sAssetID)) {
						
 					oAccessAllowed.access_meter = oMeter;
 					verbose('Asset already viewed, allowing access', oAccessAllowed);
 					oAccessAllowed.granting_method = kMethod;
					return oAccessAllowed;					
						
				}
				
				// The meter has gone passed the allowed range. Note that
				// the meter will stop growing once it has hit the limit
				// of the views.
				
				if ( nCount >= oMeter.meter ) {
						
 					verbose('Views exceeded meter limit');
					continue;
 	
				}
					
				// Provide access based on new metered view
	
				oMeterMgr.updateMeters([oMeter.id]);
				oMeterMgr.saveAssetToList(sAssetID);
						
 				oAccessAllowed.access_meter = oMeter;
 				oAccessAllowed.access_meter.count = nCount + 1;
 					
				verbose('Access provided by meter', oAccessAllowed);
				oAccessAllowed.granting_method = kMethod;
				
 				return oAccessAllowed;
				
			} else if ( aAccessIDs.indexOf(kMethod.id) !== -1 ) {
				
				verbose('Access provided by method', kMethod);
				oAccessAllowed.granting_method = kMethod;
				return oAccessAllowed;
				
			} else if ( kMethod.type == 'customjavascript' ) {
				
				try {
					
					let oFunc = new Function(kMethod.metadata.js),
						oObj = oFunc();
					
					if ( oObj && oObj.has_access ) {
						verbose('Access provided by custom javascript method', kMethod);
						oAccessAllowed.granting_method = kMethod;
						return oAccessAllowed;
					}
					
					if ( ! kMethod['hidden'] ) {
						kMethod['response'] = oObj.data || {};
						aReturnMethods.push(kMethod);
						verbose('Added access method', kMethod);
					}
					
				} catch ( oErr ) {
					verbose('Custom javascript method did not execute properly', oErr);
				}				
			} else if ( ! kMethod['hidden'] ) {				
				// Remaining methods are returned as options to gain access
				aReturnMethods.push(kMethod);
				verbose('Added access method', kMethod);
			}
		}
		
		var oResp = {
			required: true,
			access_rule: kRule,
			access_methods: aReturnMethods,
			granting_method: null,
			offer_config: kRule.offer_config || {}		
		};
		
		verbose('Access response', oResp);
		
		return oResp;
	};
	
	/* }}} */
	/* {{{ Request( oCfg ) */
	
	/**
	 * Request class to handle sending an receiving
	 * requests from the server.
	 */
	var Request = function( oCfg ) {
		if ( ! oCfg.url || ! oCfg.success ) {
			throw 'Missing URL or handler';
		}

		function _success( oEvt ) {
			if ( oEvt.target.status >= 400 ) {				
				if ( typeof this.failure == 'function' ) {
					this.failure.call(this.scope, this);
				}				
			} else if ( typeof this.success == 'function' ) {
				this.success.call(this.scope, this);
			}
		};
		
		function _failure( oEvt ) {
			if ( typeof this.failure == 'function' ) {
				this.failure.call(this.scope, oEvt);
			}
		};
		
		function _request() {
			this.request = new XMLHttpRequest();
			this.request.success = oCfg.success;
			this.request.failure = oCfg.failure;
			this.request.scope = oCfg.scope || this;
			this.request.addEventListener("load", _success);			
			this.request.addEventListener("error", _failure);
			
			var sURL = '',
				oMatch = document.location.pathname.match(
					/.+?\/tncms\/admin\/action\/main\/preview\/site\//
				),
				sHost = document.location.hostname;
			
			if ( oMatch ) {
				sHost += oMatch[0] + '-';
			} 
			
			sURL = 'https://' + sHost + '/tncms/access/' + oCfg.url;				
			
			if ( oCfg.params ) {
				var aParams = [];
				for ( var s in oCfg.params ) {
					aParams.push(s + '=' + encodeURIComponent(oCfg.params[s]));
				}
				sURL += '?' + aParams.join('&');
			}
		
			this.request.open(oCfg.method || 'GET', sURL, true);
			
			if ( oCfg.headers && oCfg.headers.length ) {
				var oHeader = null;
				for ( var n = 0; n < oCfg.headers.length; n++ ) {
					oHeader = oCfg.headers[n];
					this.request.setRequestHeader(oHeader.name, oHeader.value);
				}
			}			
			
			this.request.send();
		};
		
		return {
			
			/* {{{ new Request */
			
			/**
			 * Create an instance of the request object
			 * 
			 * @param obj {
			 * 	url string 
			 * 		URL fragment endpoint for access controller
			 * 	method string
			 * 		If something other than GET is needed it can be
			 * 		passed here. 
			 *  params obj
			 *  	An object of param names and values to include
			 *  	in the request
			 *  	{
			 *  		paramName: '11111'
			 *  	}
			 *  headers obj[]
			 *  	An array of name/value objects
			 *  	[{
			 *  		name: 'Cache-Control',
			 *  		value: 'private, max-age=86400'
			 *  	}]
			 * 	success fn 
			 * 		Called when the request has completed. The 
			 * 		function is passed the response object.
			 * 	failure fn
			 * 		Called upon failure. The function is passed
			 * 		the response object.
			 * 	scope obj
			 * 		If passed will call the function with the
			 * 		scope object.
			 * }
			 *
			 */
			
			/* }}} */
			/* {{{ send() */
			
			/**
			 * Send a configured request to the server
			 * 
			 */
			send: _request
			
			/* }}} */
		}
	};
		
	/* }}} */
	/* {{{ API: TNCMS.Access */
	
	return {
		
		/* {{{ checkAcess( fnSuccess, fnFailure ) */
		
		/**
		 * Check if access is required to view content
		 * on this page.
		 * 
		 * @param fnSuccess func
		 * 	Called when the access check is complete. The function
		 * 	is passed an access check response object.
		 * 
		 * 	If access was provided by a meter then the `access_meter`
		 * 	will be populated with the meter that was used.
		 * 
		 * 	response {
		 * 		required: true|false,
		 * 		access_methods: [{ 
		 * 			id: '', 
		 * 			name: '', 
		 * 			type: '' 
		 * 		}],
		 * 		access_rule: \TNCMS\Access\Rule,
		 * 		access_meter: {
		 * 			id: '', 
		 * 			name: '', 
		 * 			meter: 0, 
		 * 			count: 0,
		 * 			messages: [{
		 * 				id: '', 
		 * 				message:, '', 
		 * 				show_at: 0
		 * 			}]
		 *      }
		 * 	}
		 * 
		 * @param fnFailure func
		 * 	Called when a check fails for an unknown reason. The
		 * 	function is passed anonymous object containing information
		 * 	about the error.
		 * 
		 * 	err {
		 * 		success: false,
		 * 		msg: 'Error message'
		 * 	}
		 * 	
		 */
		checkAccess: function ( fnSuccess, fnFailure ) {
			if ( typeof fnSuccess !== 'function' ) {
				throw 'Access success not defined';
			}
			
			onSuccess = fnSuccess;
			
			if ( bLoaded && oAccessState ) {
				verbose('Using cached user access state for success');
				onSuccess.call(this, oAccessState);
				return;
			}
			
			onFailure = fnFailure;
			
			init.call(this);
		}, 
		
		/**
		 * Revalidate an access check if it needs to be done
		 * again on the same page load, for example if an
		 * access method is completed via an ajax request.
		 * 
		 * @see checkAccess
		 * 
		 * @param fnSuccess
		 * @param fnFailure
		 */
		revalidateAccess: function ( fnSuccess, fnFailure ) {
			if ( typeof fnSuccess !== 'function' ) {
				throw 'Access success not defined';
			}
						
			onSuccess = fnSuccess;
			onFailure = fnFailure;
			
			var nTimeStamp = Math.round((new Date()).getTime() / 1000);
			
			document.cookie = 'tncms-access-user-version=' + nTimeStamp + '; path=/; SameSite=Strict';
			// reload user data to see if they now have access
			verbose('revalidating access');
			loadUser.call(this);
		},
	
		/* }}} */
		
		/**
		 * Set audience data for this class then call processRules
		 * since that method relies on this data. If audiences has 
		 * already been initialized it return. Audiences may have been
		 * set through a test profile if in preview mode.
		 * 
		 * @param array aResult
		 *  The array of audience data objects
		 */
		setAudiences: function(aResult) {
			if ( aAudiences && aAudiences.length > 0 ) {
				verbose('Audiences already initialized', aAudiences);
				return;
			}
			
			aAudiences = aResult || [];
			verbose('Setting audiences', aResult);
			processRules.call(this);
		},
		
		/**
		 * Initialize the library to look for external properties
		 * when evaluating some access criteria such as referrer,
		 * remote host and URL matching.
		 * 
		 * @param object 
		 * 	referrer string
		 * 		URL to use with referrer checks in external mode
		 * 	metaTags array
		 * 		An array of name/content objects
		 */
		initializeExternal: function ( oOpt ) {
			bIsExternal = true;
			if ( ! oOpt ) {
				return;
			}
			
			if ( oOpt.referrer ) {
				sExternalReferrer = oOpt.referrer;
				verbose('Set external referrer', sExternalReferrer);
			}
			
			if ( oOpt.metaTags ) {
				aMetaTags = oOpt.metaTags;
				verbose('Set external meta tags', aMetaTags);
				for ( let n = 0; n < aMetaTags.length; n++ ) {
					let oMeta = aMetaTags[n];
					if ( oMeta.name && oMeta.content
						&& oMeta.name == 'tncms-access-asset-id'
					) {
						sAssetID = oMeta.content;
						verbose('Set asset ID', sAssetID);
					}
				}
			}
			
			// External URL is set based on the passed in value
			
			if ( oOpt.url ) {
				sExternalURL = oOpt.url || null;
				verbose('Set external URL', sExternalURL);
			}

		},
		
		/**
		 * ASYNC: Return all offer groups assigned to rules.
		 * 
		 * @return object
		 * {
		 * 		groupID1: {
		 * 			id: string,
		 * 			message: string,
		 * 			name: string,
		 * 			offers: array,
		 * 			promo_image: string,
		 * 			redirect_url: string,
		 * 			title: string,
		 * 			offer_group_id: string,
		 * 			priority: integer,
		 * 			type: string 
		 * 		},
		 * 		groupID2: {
		 * 			...
		 * 		},
		 * 		...
		 * }
		 */
		getOfferGroups: async function ( ) {
			let oGroups = {},
				oJSON = {},
				oRule,				
				n = 0;	
			
			await fetch('/tncms/access/rules/', {
				headers: {
					'X-TNCMS-Access-Version': sRuleVersion
				}
			})
			.then(oResp => oResp.json())
			.then(oData => { oJSON = oData });
							
			if ( oJSON.rules && oJSON.rules.length ) {				
				for ( n; n < oJSON.rules.length; n++ ) {
					oRule = oJSON.rules[n];
					if ( oRule.offer_config ) {
						oGroups[oRule.offer_config.id] = oRule.offer_config;
					}
				}
			} 

			
			return oGroups;
		},
		
		/**
		 * ASYNC: Return the list of offers bound to the group.
		 * 
		 * @param string Group ID 
		 * @return array
		 */
		getOffers: async function ( sGroupID ) {
			let oGroups = await this.getOfferGroups(),
				aOffers = [];
			
			if ( oGroups[sGroupID]
				&& oGroups[sGroupID].offers
				&& oGroups[sGroupID].offers.length
			) {
				aOffers = oGroups[sGroupID].offers;
			}
			
			return aOffers;
		},
		
		/**
		 * Process queued functions when the access library has loaded
		 * 
		 * Allows for asyncronous setup and execution of the access library. The
		 * order of registration doesn't matter as either will result in the
		 * proper execution of the library.
		 * 
		 * @param {TNCMS~Access~Push} fnCallback
		 *  Function to process when the class loads
		 */
		push: function(fnCallback) {
			try {				
				if ( typeof fnCallback !== 'function' ) {
					throw 'Value provided is not a function';
				}
				
				fnCallback();
			} catch ( oErr ) {
				console.log(oErr);
			}
		}		
	};
	
	/* }}} */
	
})();


/**
 * Callback that is processed by the push() method of the TNCMS.Access class
 * @callback TNCMS~Access~Push
 */

// Trigger any pushable callbacks

for( let nItem = 0; nItem < aCallbacks.length; nItem++ ) {
	window.TNCMS.Access.push(aCallbacks[nItem]);
}

